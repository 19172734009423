// Mixin for circle icons
@mixin iconCircle($width) {
  padding: $width;
  border-radius: 50%;
}

.eventNavigation {
  &__header {
    position: fixed;
    left: 10px;
    top: 0;
    width: calc(100% - 10px);
    z-index: 70;
    background: linear-gradient(90deg, #f7f9fa 0%, #fafafa 100%);
    color: $font-dark-grey;
  }
  &__container {
    padding: 0 40px 0 12px;
    border-bottom: 1px solid #ebeef1;
    @include flexbox;
    a:not([href]):not([class]):hover {
      color: $white;
    }
    a.text-decoration-none {
      &:hover{
        text-decoration: none;
      }
    }
    a.outer-link{
      padding: 5px 0 0 0;
      &:hover{
        text-decoration: none;
        border:none;
        cursor: default;
      }
    }
    a {
      @include font-medium($size: 14px, $lineHeight: "normal");
      color: $black;
      padding: 5px 16px 8px 10px;
      transition: background-color 300ms ease-out, color 300ms ease-in-out;
      &:first-child {
        margin-left: 0;
      }
      margin: 0 3px;
      &:hover,
      &.active_link {
        color: rgba($color: #00aeff, $alpha: 1);
        border-bottom: 2px solid #00aeff;
      }
    }

    .spinner-border-sm {
      align-self: center;
    }
    @media screen and (max-width: 990px) {
     position: fixed;
     bottom: 0;
     left:0;
     justify-content: space-between;
     width: 100%;
     background-color: white;
     box-shadow: 0px -1px 30px 1px rgba(0,0,0,0.1);
     margin: 0 !important;
     padding: 15px 5px 0 5px;
    }
  }
}

.eventHeader {
  @include flexbox;
  justify-content: space-between;
  border-bottom: 1px solid #ebeef1;
  padding: 11.5px 0px;
  width: 100%;
  padding-bottom: 0.55rem !important;
  .icon-round {
    border-radius: 10px;
    padding: 7px 18px;
    cursor: pointer;
    margin-right: 4px;
    margin-left: 4px;
    transition: 300ms ease-in background-color;
  }
  &__calendar {
    @include flexbox;
    align-items: center;
    background-color: #9a9a9a;
    transition: background-color 300ms ease-in;
    &:first-child {
      border-top-left-radius: 9px;
      border-bottom-left-radius: 9px;
    }
    &:last-child {
      border-top-right-radius: 9px;
      border-bottom-right-radius: 9px;
    }
  }
  &__calendar-arrow {
    padding: 3.5px 9px;
    cursor: pointer;

    &:hover {
      background-color: rgba($color: $white, $alpha: 0.3);
      &.right {
        border-top-right-radius: 17.5px;
        border-bottom-right-radius: 17.5px;
      }
      &.left {
        border-top-left-radius: 17.5px;
        border-bottom-left-radius: 17.5px;
      }
    }
  }
  &__calendar-center {
    padding: 3.5px 6px;
    border-right: 1px solid rgba($color: $white, $alpha: 0.05);
    border-left: 1px solid rgba($color: $white, $alpha: 0.05);
    cursor: pointer;
    position: relative;
    .react-datepicker__input-container {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      input {
        opacity: 0;
      }
    }
    .react-datepicker-popper {
      left: -125px !important;
      top: 50% !important;
    }
    &:hover {
      background-color: rgba($color: $white, $alpha: 0.3);
    }
  }
  &__toggle {
    padding: 3.5px 5px;
    border-radius: 8.5px;

    .custom-switch {
      margin-left: 0.5rem;
    }
    .custom-control-input {
      height: 1rem;
    }
    &.published {
      background-color: $green !important;
      .custom-control-input:checked ~ .custom-control-label::before {
        background-color: $white;
        border-color: #fff;
      }
      .custom-switch
        .custom-control-input:checked
        ~ .custom-control-label::after {
        background-color: $green;
      }
    }
    &.unpublished {
      background-color: #9a9a9a;
    }

    label {
      @include font-bold($size: 14px, $lineHeight: "normal");
      color: $white;
      @include flexbox;
      align-items: center;
    }
  }
  &__todo {
    background-color: #4084bf !important;
    &:hover {
      background-color: darken($color: #4084bf, $amount: 20%) !important;
    }
  }
  &__note {
    background-color: #f2c932 !important;
    &:hover {
      background-color: darken($color: #f2c932, $amount: 20%) !important;
    }
  }
  &__map {
    background-color: #f57f4e !important;
    &:hover {
      background-color: darken($color: #f57f4e, $amount: 20%) !important;
    }
  }
  &__visible {
    background-color: #627ce1 !important;
    &:hover {
      background-color: darken($color: #627ce1, $amount: 20%) !important;
    }
  }
  &__maximize {
    background-color: #fc3480 !important;
    &:hover {
      background-color: darken($color: #fc3480, $amount: 20%) !important;
    }
  }
  &__file {
    background-color: #9a9a9a !important;
    &:hover {
      background-color: darken($color: #9a9a9a, $amount: 20%) !important;
    }
  }
  &__title {
    @include font-bold($size: 16px, $lineHeight: "normal");
    color: $black;
    max-width: 500px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
  }
  &__actions {
    @include flexbox;
    align-items: center;
  }
  &__details {
    @include flexbox;
    flex-wrap: wrap;
    color: $font-dark-grey;
    span {
      margin-right: 5px;
      margin-left: 5px;
    }
  }

  .popup-content {
    width: 35% !important;
    border: none !important;
    box-shadow: none !important;
    background: none !important;
    padding: 0 24px !important;
    @media screen and (min-width: 1340px) {
      width: 33% !important;
    }
    @media screen and (min-width: 1480px) {
      width: 31% !important;
    }
    @media screen and (min-width: 1560px) {
      width: 28% !important;
    }
    @media screen and (min-width: 1680px) {
      width: 24% !important;
    }
  }

  &__distances {
    padding-bottom: 18px;
    background-color: $white;
    flex: 1;
    margin-top: 8px;
    padding: 0 8px;

    h3 {
      padding: 8px;
      @include font-medium(18px, "normal");
      color: $black;
      border-bottom: 1px solid rgba($color: $black-bg, $alpha: 0.1);
    }
    p {
      padding: 4px 8px;
      @include font-medium(14px, "normal");
      color: $black;
    }
    a {
      padding: 2px 8px;
    }

    table {
      thead th {
        color: $black;
        @include font-medium(14px, "normal");
      }
      tbody td {
        @include font-regular(12px, "normal");
        color: $black;
      }
    }
  }
}

.icon-round {
  border-radius: 30px;
  padding: 7px;
  cursor: pointer;
  margin-right: 4.5px;
  margin-left: 4.5px;
  transition: 300ms ease-in background-color;
  svg {
    font-size: 1.25em;
  }
}

[data-theme="dark"] {
  .eventHeader {
    &__distances {
      th {
        color: $almost-white;
      }
      td {
        color: $white;
      }
      background-color: #21242f;
      p {
        color: #9d9fa1;
      }
    }
    &__toggle {
      background-color: #313440;
    }
  }
}

/* GENERAL FUNCTIONALITY*/
/*.eventHeader__actions
  .moreMenu{
    display:none !important;
  }*/
@media (max-width: 1200px) {
  .eventHeader__todo,
  .eventHeader__note,
  .eventHeader__map,
  .eventHeader__file,
  .eventHeader__maximize {
    display: none !important;
  }
  .eventHeader__actions .moreMenu {
    display: block !important;
    background:#497ad8 !important;
  }
}


.eventHeader__actions .btn-sm {
  width: auto;
  height: auto;
  padding: 5px 5px;
  border-radius: 5px;
  background: #9a9a9a;
  color: #fff;
  border: 0px;
}

.eventHeader__actions .btn-sm .MuiSvgIcon-root {
  height: 22px;
}
